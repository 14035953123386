import React from 'react';
import { Box, Flex, Text, Button } from 'rebass';
import { useRouter } from 'next/router';
import { IoMdLink } from 'react-icons/io';
import CopyPublicLink from '@/components/Invitations/ShareInvitations/CopyPublicLink';
import { useCelebrationPublicURL, useCelebrationTitle } from '@/hooks/celebration';
import { Span, UpArrow } from '../CelebrationHomeScreen/common/ReusableComponents';

function CopyShareLink({
  id,
  showLabel = false,
  textAlign = 'left',
  isFromMobile,
  isNotVGB = false,
}) {
  const { pathname } = useRouter();
  const title = useCelebrationTitle(id);
  const publicLink = useCelebrationPublicURL(id);

  return (
    <Box pt={1} width="100%">
      <Flex flexDirection="column" textAlign="left">
        {showLabel && (
          <Text
            fontWeight={400}
            fontSize={18}
            color="#505050"
            mb={2}
            lineHeight="130%"
            textAlign={textAlign}
          >
            {pathname === '/'
              ? `Share the invitation link below so people can leave a message for ${title}!`
              : `Share the link below with your friends and family!`}
          </Text>
        )}
        <CopyPublicLink celebrationId={id}>
          <Flex
            sx={{
              border: isNotVGB ? '1px dashed #342E57' : '2px dashed #342E57',
              borderRadius: isNotVGB ? '100px' : '8px',
              background: '#fff',
            }}
          >
            <Flex
              alignItems="center"
              sx={{
                p: 2,
                background: '#fff',
                border: 0,
                fontWeight: 700,
                width: ['calc(100vw - 168px)', '100%'],
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                borderRadius: isNotVGB ? '100px' : '8px',
                color: '#13C283',
              }}
            >
              {publicLink}
            </Flex>
            <Button
              bg={isNotVGB ? '#13C283' : '#fff'}
              width={isNotVGB && '180px'}
              sx={{ borderRadius: isNotVGB ? '100px' : '8px', display: isNotVGB && 'flex' }}
            >
              <Text as={IoMdLink} color={isNotVGB ? '#fff' : '#13C283'} size={24} />
              {isNotVGB && (
                <Text
                  as="span"
                  sx={{
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '140%' /* 22.4px */,
                    letterSpacing: '-0.352px',
                  }}
                >
                  &nbsp;Copy link
                </Text>
              )}
            </Button>
          </Flex>
        </CopyPublicLink>
        {!isNotVGB && (
          <Flex mt={1}>
            <Span
              mr={1}
              sx={{
                fontSize: '18px',
                fontStyle: 'italic',
                letterSpacing: '-0.396px',
                lineHeight: '120%',
                fontWeight: 500,
              }}
            >
              {isFromMobile ? 'tap to copy link' : 'click to copy link'}
            </Span>
            <UpArrow />
          </Flex>
        )}
      </Flex>
    </Box>
  );
}

export default CopyShareLink;
