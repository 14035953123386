import React, { useContext, useMemo, useState, createContext } from 'react';

export const CelebrationListContext = createContext({});

export const useCelebrationList = () => {
  const [state, dispatch] = useContext(CelebrationListContext);
  const layer = useMemo(() => [state, dispatch], [state, dispatch]);
  return layer;
};

export function CelebrationList({ children }) {
  const [listOfCelebrations, setListOfCelebrations] = useState([]);
  const layer = useMemo(
    () => [listOfCelebrations, setListOfCelebrations],
    [listOfCelebrations, setListOfCelebrations]
  );
  return (
    <CelebrationListContext.Provider value={layer}>{children}</CelebrationListContext.Provider>
  );
}
