import React from 'react';
import PropTypes from 'prop-types';
import RouterLink from '@/components/RouterLink';
import { useCelebrationRoute } from '@/hooks/routes';
import { useMobileCheckHook } from '@/hooks/celebration';

function CelebrationLink({ id, children, sx = {} }) {
  const isFromMobile = useMobileCheckHook();
  const celebrationRouteParams = useCelebrationRoute(id, 'event', isFromMobile);
  return (
    <RouterLink
      {...celebrationRouteParams}
      sx={{ textDecoration: 'none', color: 'inherit' }}
      {...sx}
    >
      {children}
    </RouterLink>
  );
}
CelebrationLink.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isVideoScreenBlocked: PropTypes.bool.isRequired,
};

export default CelebrationLink;
