import React, { useMemo, useEffect, useState } from 'react';
import { ReactComponent as OneCelebration } from '@/public/Images/NewDesignImages/message--text-single.svg';
import { ReactComponent as VideoPlay } from '@/public/Images/NewDesignImages/video-play.svg';
import { ReactComponent as VGBSingle } from '@/public/Images/NewDesignImages/vgb-single.svg';
import {
  CELEBRATE_CLASSIC_LEARN_MORE_PAGE,
  CELEBRATE_CLASSIC_MARKETING_PAGE,
  CELEBRATE_QA_LEARN_MORE_PAGE,
  CELEBRATE_QA_MARKETING_PAGE,
  CELEBRATE_VGB_LEARN_MORE_PAGE,
  CELEBRATE_VGB_MARKETING_PAGE,
} from '@/lib/constants';
import { useGetCelebrations } from './api';
import { useGetTemplateData } from './celebration';

function GetTitleTheme(data, iframeSources = []) {
  switch (data?.kind) {
    case 'normal':
      return {
        type: data?.title,
        icon: <OneCelebration />,
        helpLink: CELEBRATE_CLASSIC_MARKETING_PAGE,
        noOfCredits: data?.['number-of-credits'],
        learnMoreLink:
          iframeSources.length > 0
            ? iframeSources.filter((iframe) => iframe.key === 'classic')[0]?.value
            : null,
        queryParam: '?classic=true',
        imageUrl: data['hero-1-url'],
        description: data?.description,
      };
    case 'question_and_answer':
      return {
        type: data?.title,
        icon: <VideoPlay />,
        helpLink: CELEBRATE_QA_MARKETING_PAGE,
        learnMoreLink:
          iframeSources.length > 0
            ? iframeSources.filter((iframe) => iframe.key === 'qanda')[0]?.value
            : null,
        queryParam: '?qanda=true',
        imageUrl: data['hero-1-url'],
        description: data?.description,
      };
    case 'video_guest_book':
      return {
        type: data?.title,
        icon: <VGBSingle />,
        helpLink: CELEBRATE_VGB_MARKETING_PAGE,
        learnMoreLink:
          iframeSources.length > 0
            ? iframeSources.filter((iframe) => iframe.key === 'vgb')[0]?.value
            : null,
        queryParam: '?videoguestbook=true',
        imageUrl: data['hero-1-url'],
        description: data?.description,
      };
    default:
      break;
  }
}

function getUrlBasedOnType(url) {
  if (url.includes('/classic')) return 'classic';

  if (url.includes('/qanda')) return 'qanda';

  return 'vgb';
}

function groupArrayByTheme(data, templates, iframeSources) {
  if (!data) return [];

  const sortedTemplates = templates.sort((a, b) => {
    const order = { video_guest_book: 1, normal: 2, question_and_answer: 3, unknown: 4 };
    return order[a.kind] - order[b.kind];
  });
  const themeArrays = {};
  sortedTemplates.forEach((template) => {
    const theme = template?.kind;
    if (!themeArrays[theme] && theme !== 'unknown') {
      themeArrays[theme] = {
        templateId: template?.id,
        ...GetTitleTheme(template, iframeSources),
        data: [],
      };
    }
    data.data.forEach((item) => {
      if (template.id === item?.relationships['celebration-template']?.data?.id) {
        themeArrays[theme]?.data.push(item);
      }
    });
  });

  return Object.values(themeArrays);
}

const useGetCelebrationTypes = () => {
  const [listOfCelebrationTypes, setListOfCelebrationTypes] = useState(null);
  const { data } = useGetCelebrations();
  const [iframeSources, setIframeSources] = useState([]);
  const [templateData] = useGetTemplateData();

  const templates = useMemo(() => {
    if (!templateData) return [];
    return templateData.map((oc) => ({
      ...oc.attributes,
      label: oc.attributes.name,
      kind: oc.attributes.kind,
      category: oc.attributes.category,
      value: oc.id,
      selected: !!oc.relationships.member.data,
    }));
  }, [templateData]);

  const fetchWebsiteContent = async () => {
    try {
      const responses = await Promise.all([
        fetch(`/api/renderpage?url=${CELEBRATE_CLASSIC_LEARN_MORE_PAGE}`),
        fetch(`/api/renderpage?url=${CELEBRATE_QA_LEARN_MORE_PAGE}`),
        fetch(`/api/renderpage?url=${CELEBRATE_VGB_LEARN_MORE_PAGE}`),
      ]);
      const updatedSrc = [];
      responses.map(async (response) => {
        if (response.ok) {
          const websiteContent = await response.text();
          updatedSrc.push({
            key: getUrlBasedOnType(response.url),
            value: `data:text/html,${encodeURIComponent(websiteContent)}`,
          });
        }
      });
      setIframeSources(updatedSrc);
    } catch (newError) {
      console.error('Error fetching website content:', newError);
    }
  };

  useEffect(() => {
    fetchWebsiteContent();
  }, []);

  useEffect(() => {
    if (data?.data?.data && templates.length) {
      setListOfCelebrationTypes(groupArrayByTheme(data?.data, templates, iframeSources));
    }
  }, [data?.data?.data, templates, iframeSources]);

  return useMemo(() => listOfCelebrationTypes, [listOfCelebrationTypes]);
};

export default useGetCelebrationTypes;
