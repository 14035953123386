import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { FiPlus, FiHelpCircle } from 'react-icons/fi';
import { Box, Text, Button, Flex, Image } from 'rebass';
import { clearCelebrationId, sendTrackingEvent } from '@/lib/helper';
import CelebrationListItem from '@/components/CelebrationList/CelebrationListItem';
import { useCelebrationList } from '@/components/CelebrationList/CelebrationListContext';
import AnimatedDialog from '@/components/app/AnimatedDialog';
import { cssHandling } from '@/components/Printables/ReusableComponents';
import OutlineButton from './app/OutlineButton';
import CheckBox from './app/CheckBox';

function RenderCreateButton({ isFromMobile, children, ...props }) {
  if (isFromMobile)
    return (
      <OutlineButton height={40} buttonSx={{ m: 'auto', mt: 2 }} {...props}>
        {children}
      </OutlineButton>
    );

  return (
    <Button variant="special" {...props}>
      {children}
    </Button>
  );
}

function CelebrationTemplateBox({
  description,
  imageUrl,
  data,
  queryParam,
  icon,
  type,
  clearCache,
  learnMoreLink,
  isFromMobile,
  showLearnMore = false,
  isSelected = false,
  noOfCredits,
  onClick = () => {},
}) {
  const { push } = useRouter();

  const [listOfCelebrations, setListOfCelebrations] = useCelebrationList();
  const [iframeSrc, setIframeSrc] = useState(null);

  useEffect(() => {
    setListOfCelebrations(
      data.sort(
        (a, b) => new Date(b?.attributes?.['created-at']) - new Date(a?.attributes?.['created-at'])
      )
    );
  }, [data]);

  const onLearnMoreClick = () => setIframeSrc(learnMoreLink);

  return (
    <Flex
      flexDirection="column"
      alignItems="stretch"
      textAlign="center"
      height={isFromMobile ? 'max-content' : '600px'}
      justifyContent="flex-start"
      sx={{
        borderRadius: isFromMobile ? 0 : 'medium',
        position: 'relative',
        border: isSelected ? '1px solid #7B33FB' : !isFromMobile && '0.5px solid #999',
        boxShadow: isSelected && '0px 10px 22px 4px rgba(111, 225, 247, 0.2)',
        background: isSelected ? 'linear-gradient(90deg, #9DEFFF 0%, #E3E0FF 105.43%)' : 'white',
      }}
      pb={3}
      onClick={onClick}
    >
      <Flex
        alignItems="center"
        p={1}
        sx={{
          background: isFromMobile
            ? 'linear-gradient(90deg, #9DEFFF 0%, #E3E0FF 105.43%)'
            : '#F4F6F7',
          borderRadius: isFromMobile ? 0 : '8px 8px 0px 0px',
        }}
        justifyContent="space-between"
      >
        <Flex>
          {icon}
          <Text
            sx={{
              ml: 2,
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '130%' /* 23.4px */,
              letterSpacing: '-0.396px',
              color: '#222333',
            }}
          >
            {type}
          </Text>
        </Flex>
        {showLearnMore ? (
          <CheckBox active={isSelected} bg="white" onClick={onClick} />
        ) : (
          <Text
            as={FiHelpCircle}
            size={24}
            color="#222333"
            sx={{ cursor: 'pointer' }}
            disabled={!learnMoreLink}
            onClick={onLearnMoreClick}
          />
        )}
        <AnimatedDialog
          isOpen={!!iframeSrc}
          contentProps={{ width: '100vw', maxWidth: 922, mt: 2 }}
          contentCSS={isFromMobile && cssHandling()}
          onDismiss={() => setIframeSrc(null)}
        >
          <iframe
            src={iframeSrc}
            title="Embedded Website"
            width="100%"
            height="800"
            style={{ border: 'none', marginTop: '8px' }}
          />
        </AnimatedDialog>
      </Flex>
      {!!noOfCredits && (
        <Flex
          sx={{
            borderRadius: '0px 0px 5px 5px',
            border: '0.5px solid #999',
            background: '#13C283',
          }}
          justifyContent="center"
          p="20px 10px"
        >
          <Text
            sx={{
              color: '#fff',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '130%' /* 23.4px */,
              letterSpacing: '-0.352px',
            }}
          >
            You have{' '}
            <b>
              {noOfCredits} unused {type} {noOfCredits > 1 ? 'credits' : 'credit'}.
            </b>
          </Text>
        </Flex>
      )}

      {listOfCelebrations.length > 0 && !showLearnMore ? (
        <Box
          m={2}
          mb={1}
          height={!isFromMobile && listOfCelebrations.length > 3 ? 450 : '100%'}
          overflowY="auto"
        >
          {listOfCelebrations.map(({ id }) => (
            <CelebrationListItem
              key={`${id}-list-item`}
              id={id}
              isFromMobile={isFromMobile}
              clearCache={clearCache}
            />
          ))}
        </Box>
      ) : (
        <Box p={2}>
          <Box mb={2}>
            <Image src={imageUrl} maxHeight={310} />
          </Box>
          <Text
            fontWeight={400}
            fontSize={['16px', '18px']}
            lineHeight="140%"
            color="#505050"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Box>
      )}
      {showLearnMore ? (
        <OutlineButton
          width="calc(100% - 32px)"
          maxHeight={43}
          onClick={onLearnMoreClick}
          buttonProps={{ mx: 'auto' }}
        >
          Learn More
        </OutlineButton>
      ) : (
        <RenderCreateButton
          isFromMobile={isFromMobile}
          py={1}
          m={2}
          mt="auto"
          onClick={() => {
            clearCelebrationId();
            clearCache();
            sendTrackingEvent(`member_visit`, {
              context: {
                url_text: `Create ${type}`,
                url: `/event${queryParam}`,
              },
            });
            push(`/event${queryParam}`);
          }}
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            sx={{
              borderRadius: 'medium',
            }}
          >
            <Text
              as={FiPlus}
              color={
                isFromMobile
                  ? 'linear-gradient(90deg, #3866F4 0%, #7B33FB 48.96%, #BD00FF 100%)'
                  : 'white'
              }
              size={24}
            />
            <Text
              sx={{
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '130%' /* 23.4px */,
                letterSpacing: '-0.396px',
              }}
            >
              Create {type}
            </Text>
          </Flex>
        </RenderCreateButton>
      )}
    </Flex>
  );
}

export default CelebrationTemplateBox;
