import React from 'react';
import { useRouter } from 'next/router';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FiCheckCircle } from 'react-icons/fi';
import { useCreateConfirmationManual } from '@/components/app/ConfirmationOverlayManual';
import { useCelebrationPublicURL } from '@/hooks/celebration';

function CopyPublicLink({ children, celebrationId }) {
  const {
    query: { id },
  } = useRouter();
  const publicLink = useCelebrationPublicURL(celebrationId || id);
  const createConfirmation = useCreateConfirmationManual();
  return (
    <CopyToClipboard
      text={publicLink}
      onCopy={() =>
        createConfirmation({
          icon: FiCheckCircle,
          message: 'Link copied and is ready to paste into an email or text message',
        })
      }
    >
      {children}
    </CopyToClipboard>
  );
}

export default CopyPublicLink;
