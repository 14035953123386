import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import useOnClickOutside from 'use-onclickoutside';
import { Box, Image, Flex, Text } from 'rebass';
import { BsThreeDotsVertical, BsSend, BsCameraVideo } from 'react-icons/bs';
import { RiDeleteBinLine } from 'react-icons/ri';
// import { FiCopy } from 'react-icons/fi';
import { CgLockUnlock } from 'react-icons/cg';
import { Menu, MenuList, MenuButton, MenuItem } from '@reach/menu-button';
import { useCelebrationTitle, useCelebration, useCelebrationCoverPhoto } from '@/hooks/celebration';
import CelebrationLink from '@/components/CelebrationList/CelebrationLink';
import { useResourceRequest } from '@/hooks/api';
import PaymentModal from '@/components/CelebrationHomeScreen/common/PaymentModal';
import { postPrivateEventDetails } from '@/lib/api';
import { ConfirmButton } from '../app/ConfirmDialog';
import CopyShareLink from '../CelebrationCreation/CopyShareLink';
import CopyVideoLink from '../Invitations/ShareInvitations/CopyVideoLink';
import { useCelebrationList } from './CelebrationListContext';
import '@reach/menu-button/styles.css';

const MenuActionItems = (celebration) => {
  const menuItems = [
    {
      title: 'Share Invite',
      ImageIcon: <BsSend size={24} />,
    },
    // {
    //   title: 'Duplicate',
    //   ImageIcon: <FiCopy size={24} />,
    // },
    {
      title: 'Delete',
      ImageIcon: <RiDeleteBinLine size={24} />,
    },
    {
      title: 'Share, merged video',
      ImageIcon: <BsCameraVideo size={24} />,
      disabled: celebration.state === 'created',
    },
  ];

  if (!celebration?.paid)
    menuItems.push({
      title: 'Pay Now',
      ImageIcon: <CgLockUnlock size={24} />,
    });

  return menuItems;
};

function RenderActionModal({ selectMenuItem, celebration, setSelectedMenuItem }) {
  const [listOfCelebrations, setListOfCelebrations] = useCelebrationList();

  const revalidateCelebration = () =>
    setListOfCelebrations(listOfCelebrations.filter(({ id }) => id !== celebration?.id));

  const isClipExist = celebration && celebration?.['visible-clips-count'] > 0;
  const [deleteCelebration, { loading }] = useResourceRequest(
    'DELETE',
    `/v3/celebrations/${celebration?.id}`
  );
  const [duplicateCelebration, { loading: duplicateLoading }] = useResourceRequest(
    'PATCH',
    `/v3/celebrations/${celebration?.id}/duplicate`
  );

  switch (selectMenuItem) {
    case 'Share Invite':
      return (
        <ConfirmButton
          body={<CopyShareLink showLabel id={celebration?.id} />}
          buttonProps={{
            fontSize: 18,
            sx: { textTransform: 'uppercase', borderRadius: '0 8px 0px 0' },
            borderRadius: '0 8px 8px 0',
            maxWidth: '125px',
            bg: '#6369EF',
            height: '50%',
          }}
          isOpen
          setIsOpen={() => setSelectedMenuItem(null)}
        />
      );
    case 'Share, merged video':
      return (
        <ConfirmButton
          body={<CopyVideoLink showLabel celebrationId={celebration?.id} />}
          buttonProps={{
            fontSize: 18,
            sx: { textTransform: 'uppercase', borderRadius: '0 8px 0px 0' },
            borderRadius: '0 8px 8px 0',
            maxWidth: '125px',
            bg: '#6369EF',
            height: '50%',
          }}
          isOpen
          setIsOpen={() => setSelectedMenuItem(null)}
        />
      );
    case 'Delete':
      return (
        <ConfirmButton
          title={
            celebration?.paid
              ? 'Are you sure? This celebration has already been paid for.'
              : 'Are you sure you want to delete this celebration?'
          }
          body={
            <span>
              {isClipExist ? 'You will permanently lose all of these clips.' : ''}
              <br />
              <span style={{ lineHeight: 2 }}>This action cannot be reversed</span>
            </span>
          }
          disabled={loading}
          buttonProps={{
            fontSize: 18,
            sx: { textTransform: 'uppercase', borderRadius: '0 8px 0px 0' },
            borderRadius: '0 8px 8px 0',
            maxWidth: '125px',
            bg: '#6369EF',
            height: '50%',
          }}
          isOpen
          setIsOpen={() => setSelectedMenuItem(null)}
          onConfirm={async () => {
            await deleteCelebration();
            revalidateCelebration();
            setSelectedMenuItem(false);
          }}
        />
      );
    case 'Duplicate':
      return (
        <ConfirmButton
          title="Are you sure you want to duplicate this celebration?"
          disabled={duplicateLoading}
          bgButtonColor="linear-gradient(90deg,#3866F4 0%,#7B33FB 48.96%,#BD00FF 100%)"
          buttonProps={{
            fontSize: 18,
            sx: { textTransform: 'uppercase', borderRadius: '0 8px 0px 0' },
            borderRadius: '0 8px 8px 0',
            maxWidth: '125px',
            background: '',
            height: '50%',
          }}
          isOpen
          setIsOpen={() => setSelectedMenuItem(null)}
          onConfirm={async () => {
            await duplicateCelebration();
            // revalidateCelebration();
            setSelectedMenuItem(false);
          }}
        />
      );
    case 'Pay Now':
      return (
        <PaymentModal isOpen onDismiss={() => setSelectedMenuItem(false)} id={celebration?.id} />
      );
    default:
      return null;
  }
}

function DropDown({ celebration }) {
  const ref = useRef();
  const [isOpen, setOpen] = useState(false);
  const [selectMenuItem, setSelectedMenuItem] = useState(null);

  useOnClickOutside(ref, () => {
    if (isOpen) {
      setOpen(false);
    }
  });

  const changeOpenStatus = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setOpen(!isOpen);
    },
    [isOpen]
  );

  return (
    <>
      <RenderActionModal
        selectMenuItem={selectMenuItem}
        setSelectedMenuItem={setSelectedMenuItem}
        celebration={celebration}
      />
      <Menu>
        <MenuButton style={{ background: 'transparent', border: 0 }}>
          <Flex
            bg="#EFEFEF"
            p={1}
            sx={{ borderRadius: '50%', cursor: 'pointer' }}
            onClick={changeOpenStatus}
            mr={1}
          >
            <BsThreeDotsVertical size={16} />
          </Flex>
        </MenuButton>
        <MenuList
          style={{
            position: 'absolute',
            display: 'block',
            width: 'max-content',
            right: -25,
            boxShadow: 'medium',
            borderRadius: 'medium',
            borderSize: '3px',
            borderStyle: 'solid',
            borderColor: '#D9D9D9',
            zIndex: 10,
            cursor: 'default',
          }}
        >
          {MenuActionItems(celebration).map(({ ImageIcon, title, disabled }) => (
            <MenuItem
              key={title}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedMenuItem(title);
              }}
            >
              <Flex
                key={title}
                alignItems="center"
                transition="all 0.5s ease-in-out"
                sx={{
                  m: '10px',
                  p: '5px',
                  width: 'max-content',
                  cursor: disabled ? 'default' : 'pointer',
                  opacity: disabled ? 0.6 : 1,
                }}
              >
                {ImageIcon}
                <Text
                  ml={2}
                  sx={{
                    fontSize: '18px',
                    fontWeight: 500,
                    lineHeight: '140%',
                    letterSpacing: '-0.396px',
                  }}
                  textAlign="left"
                >
                  {title}
                </Text>
              </Flex>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
}

function CelebrationListItem({ id, clearCache }) {
  const [showPayment, setShowPaymentModal] = useState(false);

  const celebration = useCelebration(id);
  const title = useCelebrationTitle(id);
  const src = useCelebrationCoverPhoto(id);
  const occasionDate = celebration?.['occasion-date'];

  if (!celebration) return false;
  return (
    <CelebrationLink
      id={id}
      sx={{
        background: '#FAFAFA',
      }}
    >
      <Box
        onClick={() => {
          postPrivateEventDetails(`celebration_tapped_on_celebration_from_home_page`, {
            'subject-type': 'Celebration',
            'subject-id': celebration?.id,
          });
          clearCache();
        }}
        sx={{
          background: '#FAFAFA',
          position: 'relative',
          border: '0.5px solid #D9D9D9',
          borderRadius: '8px',
        }}
        mb={2}
      >
        <Flex background="#FAFAFA" justifyContent="space-between" overflow="hidden">
          <Flex width="100%">
            {src && (
              <Box height={120} sx={{ borderRadius: '8px 0px 0px 8px', position: 'relative' }}>
                <PaymentModal
                  isOpen={showPayment}
                  onDismiss={() => setShowPaymentModal(false)}
                  id={id}
                  celebrationDetail={celebration}
                />
                <Image
                  src={src}
                  width="140px"
                  height={120}
                  sx={{
                    borderRadius: '8px 0px 0px 7px',
                    objectFit: 'cover',
                    boxShadow: 'small',
                  }}
                />
                {!celebration?.paid && (
                  <Text
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowPaymentModal(true);
                      postPrivateEventDetails(`celebration_tapped_pay_now_celebration_home`, {
                        'subject-type': 'Celebration',
                        'subject-id': id,
                      });
                    }}
                    p="7px 8px 11px 11px"
                    sx={{
                      borderRadius: '0px 0px 0px 7px',
                      position: 'absolute',
                      bottom: 0,
                      color: '#FFF',
                      width: '100%',
                      bg: 'rgba(0, 0, 0, 0.65)',
                      fontSize: '16px',
                      fontWeight: 700,
                      lineHeight: '120%',
                      letterSpacing: '-0.352px',
                      textAlign: 'left',
                      cursor: 'pointer',
                      textDecorationLine: 'underline',
                    }}
                  >
                    Pay Now
                  </Text>
                )}
              </Box>
            )}
            <Box
              bg="#FAFAFA"
              ml={1}
              width={!title ? '100%' : 'calc(100% - 140px)'}
              sx={{ borderRadius: '8px', width: '100%' }}
            >
              <Flex
                justifyContent="space-between"
                alignItems="flex-end"
                width={!title && 'inherit'}
              >
                <Text
                  fontWeight="bold"
                  // pt={1}
                  fontSize={18}
                  lineHeight="-2.2%"
                  title={title}
                  sx={{
                    width: 'calc(100% - 30px)',
                    textAlign: 'left',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {title}
                </Text>
                <DropDown celebration={celebration} />
              </Flex>
              <Text
                textAlign="left"
                lineHeight="20.8px"
                fontSize={16}
                color="rgba(0, 0, 0, 0.7)"
                my={1}
              >
                {occasionDate}
              </Text>
              <Text
                textAlign="left"
                lineHeight="20.8px"
                fontSize={16}
                color="rgba(22, 22, 29, 1)"
                mt={1}
                mb="4px"
              >
                {celebration?.['contributor-clips-count'] || 0} Clips
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </CelebrationLink>
  );
}
CelebrationListItem.propTypes = { id: PropTypes.string.isRequired };

export default CelebrationListItem;
